@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow-x: hidden;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  line-height: 1;
}

body {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin: 0;
  padding: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Gets rid of react-router's default blue underline
// for all Link components
a {
  text-decoration: none;
}
